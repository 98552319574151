import axios from 'axios';
import qs from 'qs';
import { O365, LOCAL_STORAGE_ITEMS } from '@/common/data/constants';

const SET_USERS = 'O365/ORGANIZATIONS/SET_USERS';
const SET_GROUPS = 'O365/ORGANIZATIONS/SET_GROUPS';
const SET_SITES = 'O365/ORGANIZATIONS/SET_SITES';
const SET_TEAMS = 'O365/ORGANIZATIONS/SET_TEAMS';
const SET_ORG_NAMES = 'O365/ORGANIZATIONS/SET_ORG_NAMES';
const SET_JOBS = 'O365/ORGANIZATIONS/SET_JOBS';
const SET_ORGANIZATIONS_FROM_API = 'O365/ORGANIZATIONS/SET_ORGANIZATIONS_FROM_API';
const SET_ORGANIZATIONS = 'O365/ORGANIZATIONS/SET_ORGANIZATIONS';

const ORGANIZATIONS_ENDPOINT = `${O365}/organizations`;
const SINGLE_ORGANIZATION_ENDPOINT = `${ORGANIZATIONS_ENDPOINT}/:organizationId`;
const ORGANIZATION_USERS_ENDPOINT = `${SINGLE_ORGANIZATION_ENDPOINT}/users`;
const ORGANIZATION_GROUPS_ENDPOINT = `${SINGLE_ORGANIZATION_ENDPOINT}/groups`;
const ORGANIZATION_TEAMS_ENDPOINT = `${SINGLE_ORGANIZATION_ENDPOINT}/teams`;
const ORGANIZATION_SITES_ENDPOINT = `${SINGLE_ORGANIZATION_ENDPOINT}/sites`;
const ORGANIZATION_CHILDREN_SITES_ENDPOINT = `${ORGANIZATION_SITES_ENDPOINT}/:siteId/children`;
const ORGANIZATIONS_NAMES_ENDPOINT = `${ORGANIZATIONS_ENDPOINT}/names`;
const ORGANIZATIONS_JOBS_ENDPOINT = `${SINGLE_ORGANIZATION_ENDPOINT}/jobs`;
const ORGANIZATIONS_FROM_API_ENDPOINT = `${ORGANIZATIONS_ENDPOINT}/from-api`;

function initialState() {
  return {
    users: {},
    groups: {},
    sites: {},
    teams: {},
    orgNames: JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.O365_ORG_NAMES)) || [],
    jobs: [],
    organizationsFromAPI: [],
    organizations: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_O365_USERS({ commit }, { organizationId }) {
    const response = await axios.get(
      ORGANIZATION_USERS_ENDPOINT.replace(':organizationId', organizationId)
    );
    const { data } = response;
    if (data.success) {
      commit(SET_USERS, data.data);
    }
  },
  async GET_O365_GROUPS({ commit }, { organizationId }) {
    const response = await axios.get(
      ORGANIZATION_GROUPS_ENDPOINT.replace(':organizationId', organizationId)
    );
    const { data } = response;
    if (data.success) {
      commit(SET_GROUPS, data.data);
    }
  },
  async GET_O365_SITES({ commit }, { organizationId }) {
    const response = await axios.get(
      ORGANIZATION_SITES_ENDPOINT.replace(':organizationId', organizationId)
    );
    const { data } = response;
    if (data.success) {
      commit(SET_SITES, data.data);
    }
  },
  async GET_O365_CHILDREN_SITES(context, { organizationId, siteId }) {
    const response = await axios.get(
      ORGANIZATION_CHILDREN_SITES_ENDPOINT.replace(':organizationId', organizationId).replace(
        ':siteId',
        siteId
      )
    );
    const { data } = response;
    if (data.success) return data.data;
    else return [];
  },
  async GET_O365_TEAMS({ commit }, { organizationId }) {
    const response = await axios.get(
      ORGANIZATION_TEAMS_ENDPOINT.replace(':organizationId', organizationId)
    );
    const { data } = response;
    if (data.success) {
      commit(SET_TEAMS, data.data);
    }
  },
  async GET_O365_ORG_NAMES({ commit }) {
    const response = await axios.get(ORGANIZATIONS_NAMES_ENDPOINT);
    const { data } = response;
    if (data.success) {
      commit(SET_ORG_NAMES, response.data.data);
      localStorage.setItem(LOCAL_STORAGE_ITEMS.O365_ORG_NAMES, JSON.stringify(response.data.data));
    }
  },
  CLEAR_O365_ORG_NAMES({ commit }) {
    commit(SET_ORG_NAMES, []);
    localStorage.removeItem(LOCAL_STORAGE_ITEMS.O365_ORG_NAMES);
  },
  async GET_O365_ORGANIZATION_JOBS({ commit }, { organizationId, params }) {
    const response = await axios.get(
      ORGANIZATIONS_JOBS_ENDPOINT.replace(':organizationId', organizationId),
      {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      }
    );
    const { data } = response;
    if (data.success) {
      commit(SET_JOBS, data.data);
    }
  },
  CLEAR_O365_ORGANIZATION_JOBS({ commit }) {
    commit(SET_JOBS, []);
  },
  async GET_O365_ORGANIZATIONS_FROM_API({ commit }, { apiUrl, username, password }) {
    const response = await axios.post(ORGANIZATIONS_FROM_API_ENDPOINT, {
      apiUrl,
      username,
      password
    });
    const { data } = response;
    if (data.success) {
      commit(SET_ORGANIZATIONS_FROM_API, data.data);
    }
  },
  async GET_O365_ORGANIZATIONS({ commit }, { companyId }) {
    const params = { companyId };
    const response = await axios.get(ORGANIZATIONS_ENDPOINT, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true })
    });
    const { data } = response;
    if (data.success) {
      commit(SET_ORGANIZATIONS, data.data);
    }
  },
  CLEAR_O365_ORGANIZATIONS({ commit }) {
    commit(SET_ORGANIZATIONS, []);
  }
};

const getters = {
  getO365Users: state => state.users,
  getO365Groups: state => state.groups,
  getO365Sites: state => state.sites,
  getO365Teams: state => state.teams,
  getO365OrgNames: state => state.orgNames,
  getO365OrganizationJobs: state => state.jobs,
  getO365OrganizationsFromApi: state => state.organizationsFromAPI,
  getO365Organizations: state => state.organizations
};

const mutations = {
  [SET_USERS](state, data) {
    state.users = data;
  },
  [SET_GROUPS](state, data) {
    state.groups = data;
  },
  [SET_SITES](state, data) {
    state.sites = data;
  },
  [SET_TEAMS](state, data) {
    state.teams = data;
  },
  [SET_ORG_NAMES](state, data) {
    state.orgNames = data;
  },
  [SET_JOBS](state, data) {
    state.jobs = data;
  },
  [SET_ORGANIZATIONS_FROM_API](state, data) {
    state.organizationsFromAPI = data;
  },
  [SET_ORGANIZATIONS](state, data) {
    state.organizations = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
