<template>
  <BaseDashboardLayout>
    <side-bar
      slot="sidebar"
      :background-color="sidebarBackground"
      :title="sidebarTitle"
      :logo="sidebarLogo"
      :logoHeight="logoHeight"
      :logoWidth="logoWidth"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.dashboard')"
          :link="{
            name: 'Dashboard',
            icon: 'tim-icons icon-chart-bar-32',
            path: `/${O365}/dashboard`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.jobs')"
          :link="{
            name: 'Jobs',
            icon: 'fab fa-windows',
            path: `/${O365}/jobs`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.organizations')"
          :link="{
            name: 'Organizations',
            icon: 'tim-icons icon-bank',
            path: `/${O365}/organizations`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.repositories')"
          :link="{
            name: 'Repositories',
            icon: 'tim-icons icon-cloud-download-93',
            path: `/${O365}/repositories`
          }"
        />
        <sidebar-item
          v-if="
            UI_ABILITIES.check(loggedInUser.acl, 'o365.reporting') &&
              brandingGetter.features.reporting.enabled
          "
          :link="{ name: 'Reports', icon: 'tim-icons icon-book-bookmark' }"
        >
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.licensing')"
            :link="{
              name: 'Licensing',
              path: `/${O365}/licensing`
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.licensing')"
            :link="{
              name: 'Licensed Users',
              path: `/${O365}/license-consumption`
            }"
          />
          <!-- License Consumption -->
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Restore Operators',
            icon: 'fas fa-user-lock',
            path: `/${O365}/restore-operator-roles`
          }"
        />
        <sidebar-item
          v-if="restoreUrl"
          isExternalLink
          :link="{
            name: 'Restore',
            icon: 'far fa-window-restore',
            path: restoreUrl,
            target: '_blank'
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.restorePortal') && showRestore"
          :link="{ name: 'Restore (BETA)', icon: 'fas fa-window-restore' }"
        >
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.restoreOutlook') && restorePortal?.outlook"
            :link="{
              name: 'Outlook',
              path: `/${O365}/outlook`,
              icon: 'icon-restore fas fa-square-envelope fa-xl'
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.restoreOnedrive') && restorePortal?.onedrive"
            :link="{
              name: 'OneDrive',
              path: `/${O365}/onedrive`,
              icon: 'icon-restore fas fa-cloud fa-xl'
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.restoreSharepoint') && restorePortal?.sharepoint"
            :link="{
              name: 'SharePoint',
              path: `/${O365}/sharepoint`,
              icon: 'icon-restore fas fa-s fa-xl'
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.restoreTeams') && restorePortal?.teams"
            :link="{
              name: 'Teams',
              path: `/${O365}/teams`,
              icon: 'icon-restore fas fa-user-group fa-xl'
            }"
          />
        </sidebar-item>
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.subscriptions') && hasStripe"
          :link="{
            name: 'Billing',
            icon: 'fas fa-money-bill',
            path: `/${O365}/billing`,
          }"
        />
        <sidebar-item
          v-if="brandingGetter.features?.supportSection?.enabled"
          :link="{ name: 'Support', icon: 'tim-icons icon-support-17' }"
        >
          <sidebar-item
            v-if="brandingGetter.features?.supportSection?.links?.request"
            :link="{
              name: 'Request',
              path: `/${MONITORING}/support/request`
            }"
          />
          <sidebar-item
            v-if="brandingGetter.features?.supportSection?.links?.live"
            :link="{
              name: 'Live',
              path: `/${MONITORING}/support/live`
            }"
          />
          <sidebar-item
            v-if="brandingGetter.features?.supportSection?.links?.downloads"
            :link="{
              name: 'Downloads',
              path: `/${MONITORING}/downloads`
            }"
          />
        </sidebar-item>
        <!-- <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'o365.users')"
          :link="{
            name: 'Users',
            icon: 'tim-icons icon-single-02',
            path: `/${O365}/users`
          }"
        /> -->
      </template>
    </side-bar>
  </BaseDashboardLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { O365, MONITORING } from '@/common/data/constants';
import BaseDashboardLayout from '../../../common/pages/Layout/BaseDashboardLayout.vue';

export default {
  components: { BaseDashboardLayout },
  data() {
    return {
      UI_ABILITIES,
      sidebarLogo: null,
      logoWidth: undefined,
      logoHeight: undefined,
      sidebarTitle: '',
      O365,
      MONITORING,
      restoreUrl: undefined,
      brandingData: null,
      restorePortal: undefined
    };
  },
  beforeCreate() {
    this.$store.dispatch('SET_SELECTED_APP', O365);
  },
  mounted() {
    if (!this.brandingData) {
      this.brandingData = this.brandingGetter;
    }
    this.processBrandingData();
  },
  computed: {
    ...mapGetters(['loggedInUser', 'getThemeSettings', 'brandingGetter']),
    sidebarBackground() {
      return this.getThemeSettings ? this.getThemeSettings.sidebarColor : 'blue';
    },
    showRestore() {
      return (
        this.restorePortal &&
        Object.keys(this.restorePortal).length > 0 &&
        Object.values(this.restorePortal).some(value => value === true)
      );
    },
    hasStripe() {
      return this.brandingGetter.isStripeEnabled;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    processBrandingData() {
      const office365Features = this.brandingData?.features?.office365;
    
      this.sidebarLogo = this.brandingData?.sidebarLogoUrl ?? undefined;
      this.logoWidth = this.brandingData?.sidebarLogoWidth;
      this.logoHeight = this.brandingData?.sidebarLogoHeight;
      this.sidebarTitle = this.brandingData?.sidebarTitle;
      this.restoreUrl = office365Features?.restoreUrl;
      this.restorePortal = office365Features?.restorePortal || undefined;
    }
  },
  watch: {
    brandingGetter: {
      inmediate: true,
      handler(newValue) {
        this.brandingData = newValue;
        // Access brandingData properties after brandingGetter has resolved
        this.processBrandingData();
      }
    }
  }
};
</script>
