const SET_SELECTED_APP = 'app/SET_SELECTED_APP';
const SET_SIDEBAR_OPEN_MENU = 'app/SET_SIDEBAR_OPEN_MENU';

function initialState() {
  return { selectedApp: null, sidebarOpenMenu: '' };
}

const actions = {
  SET_SELECTED_APP({ commit }, data) {
    commit(SET_SELECTED_APP, data);
  },
  UPDATE_SIDEBAR_OPEN_MENU({ commit }, openMenuName) {
    commit(SET_SIDEBAR_OPEN_MENU, openMenuName);
  }
};

const getters = {
  getSelectedApp: state => state.selectedApp,
  getSidebarOpenMenu: state => state.sidebarOpenMenu
};

const mutations = {
  [SET_SELECTED_APP](state, data) {
    state.selectedApp = data;
  },
  [SET_SIDEBAR_OPEN_MENU](state, data) {
    state.sidebarOpenMenu = data;
  }
};

export default {
  state: { ...initialState() },
  actions,
  getters,
  mutations
};
