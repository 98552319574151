import axios from 'axios';
import qs from 'qs';
import FileDownload from 'js-file-download';
import moment from 'moment';
import { O365 } from '@/common/data/constants';

const SET_ONEDRIVES = 'RESTORE/SET_ONEDRIVES';
const SET_ONEDRIVE_FOLDERS = 'RESTORE/SET_ONEDRIVE_FOLDERS';
const SET_ONEDRIVE_DOCUMENTS = 'RESTORE/SET_ONEDRIVE_DOCUMENTS';

const BASE_RESTORE_ENDPOINT = `${O365}/restore`;
const RESTORE_SESSION_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/restoresessions/:restoreSessionId`;
const ONEDRIVES_ENDPOINT = `${RESTORE_SESSION_ENDPOINT}/onedrives`;
const RESTORE_ONEDRIVE_ENDPOINT = `${ONEDRIVES_ENDPOINT}/restore`;
const DOWNLOAD_ONEDRIVE_ENDPOINT = `${ONEDRIVES_ENDPOINT}/download`;
const RESTORE_ONEDRIVE_FOLDER_ENDPOINT = `${ONEDRIVES_ENDPOINT}/restore-folder`;
const DOWNLOAD_ONEDRIVE_FOLDER_ENDPOINT = `${ONEDRIVES_ENDPOINT}/download-folder`;
const RESTORE_ONEDRIVE_DOCUMENT_ENDPOINT = `${ONEDRIVES_ENDPOINT}/restore-document`;
const DOWNLOAD_ONEDRIVE_DOCUMENT_ENDPOINT = `${ONEDRIVES_ENDPOINT}/download-document`;
const ONEDRIVE_FOLDERS_ENDPOINT = `${ONEDRIVES_ENDPOINT}/:onedriveId/folders`;
const ONEDRIVE_DOCUMENTS_ENDPOINT = `${ONEDRIVES_ENDPOINT}/:onedriveId/folders/documents`;

function initialState() {
  return {
    onedrives: []
  };
}

const state = initialState();

const actions = {
  async GET_ONEDRIVES({ commit }, { restoreSessionId, params }) {
    try {
      const response = await axios.get(
        ONEDRIVES_ENDPOINT.replace(':restoreSessionId', restoreSessionId),
        {
          params: { ...params, companyId: params.companyId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_ONEDRIVES, response.data?.data?.results || response.data?.data);
    } catch (error) {
      throw 'Error getting the onedrives. Try again later.';
    }
  },
  CLEAR_ONEDRIVES({ commit }) {
    commit(SET_ONEDRIVES, []);
  },
  async GET_ONEDRIVE_FOLDERS(
    { commit },
    { restoreSessionId, onedriveId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        ONEDRIVE_FOLDERS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':onedriveId',
          onedriveId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_ONEDRIVE_FOLDERS, {
        onedriveId,
        folders: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
    } catch (error) {
      console.log(error);
      throw 'Error getting the onedrive folders. Try again later.';
    }
  },
  async GET_ONEDRIVE_DOCUMENTS(
    { commit },
    { restoreSessionId, onedriveId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        ONEDRIVE_DOCUMENTS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':onedriveId',
          onedriveId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_ONEDRIVE_DOCUMENTS, {
        onedriveId,
        documents: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
      return response.data?.data?.results || response.data?.data;
    } catch (error) {
      console.log(error);
      throw 'Error getting the onedrive folders. Try again later.';
    }
  },
  CLEAR_ONEDRIVE_FOLDERS({ commit }) {
    commit(SET_ONEDRIVE_FOLDERS, []);
  },
  async RESTORE_ONEDRIVE({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        onedriveId: params.onedriveId
      };
      const response = await axios.post(
        RESTORE_ONEDRIVE_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the onedrive. Try again later.';
    }
  },
  async DOWNLOAD_ONEDRIVE({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        onedriveId: params.onedriveId
      };
      const response = await axios.post(
        DOWNLOAD_ONEDRIVE_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data,
        {
          headers: { Accept: 'application/zip' },
          responseType: 'blob'
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.fileName}-onedrive.zip`);
    } catch (error) {
      throw 'Error restoring the onedrive. Try again later.';
    }
  },
  async RESTORE_ONEDRIVE_FOLDER({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        onedriveId: params.onedriveId,
        folders: params.folders
      };
      const response = await axios.post(
        RESTORE_ONEDRIVE_FOLDER_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async DOWNLOAD_ONEDRIVE_FOLDER({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        onedriveId: params.onedriveId,
        folders: params.folders
      };
      const response = await axios.post(
        DOWNLOAD_ONEDRIVE_FOLDER_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data,
        {
          headers: { Accept: 'application/zip' },
          responseType: 'blob'
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.fileName}-folder.zip`);
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_ONEDRIVE_DOCUMENT({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        onedriveId: params.onedriveId,
        documents: params.documents
      };
      const response = await axios.post(
        RESTORE_ONEDRIVE_DOCUMENT_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the document. Try again later.';
    }
  },
  async DOWNLOAD_ONEDRIVE_DOCUMENT({ commit, state }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        onedriveId: params.onedriveId,
        documents: params.documents
      };
      const response = await axios.post(
        DOWNLOAD_ONEDRIVE_DOCUMENT_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data,
        {
          headers: { Accept: 'application/zip' },
          responseType: 'blob'
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const onedriveName = state.onedrives.find(o => o.id === params.onedriveId).name;
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${onedriveName}-documents.zip`);
    } catch (error) {
      throw 'Error restoring the document. Try again later.';
    }
  }
};

const getters = {
  getOnedrives: state => state.onedrives
};

const mutations = {
  [SET_ONEDRIVES](state, data) {
    state.onedrives = data;
  },
  [SET_ONEDRIVE_FOLDERS](state, { onedriveId, folders, parentId, breadcrumbIds }) {
    const onedriveIdx = state.onedrives.findIndex(onedrive => onedrive.id === onedriveId);
    const onedrive = state.onedrives[onedriveIdx];
    if (onedrive) {
      let copy = { ...onedrive };
      //If parentId is Root, it means we fetched the folders of the onedrive.
      if (parentId === 'Root') {
        copy.children = [
          ...folders.map(folder => {
            return {
              name: folder.name,
              id: folder.id,
              onedriveId,
              folderObj: { ...folder },
              data: []
            };
          })
        ];
        //In here we replace the onedrive in the array with the copy that contains the folders.
        state.onedrives.splice(onedriveIdx, 1, copy);
      } else {
        //If parentId is not Root, we fetched the folders for a subfolder
        //First we check the easiest case which is a direct child of the onedrive.
        const index = copy.children.findIndex(child => child.id === parentId);
        if (index !== -1) {
          copy.children[index].children = [
            ...folders.map(folder => {
              return {
                name: folder.name,
                id: folder.id,
                onedriveId,
                folderObj: { ...folder },
                data: []
              };
            })
          ];
          state.onedrives.splice(onedriveIdx, 1, copy);
        } else {
          //If it's not a direct child of the onedrive, we follow the IDs in the breadcrumb
          //which allows us to follow the path to the selected folder for which we fetched
          //the subfolders.
          //We remove the first ID of the breadcrumb because it's the onedriveId
          breadcrumbIds.shift();
          //We're searching in the children of each folder the ID in the breadcrumb starting
          //with the onedrive children.
          let children = copy.children;
          for (let id of breadcrumbIds) {
            //we get the folder of each id in the breadcrumb and check if it has children:
            //If it does, it means we have to check inside it's children as well
            //If it doesn't, we reached the selected folder and we map the folders to it's children.
            const folder = children.find(child => child.id === id);
            if (folder?.children) {
              children = folder.children;
            } else {
              folder.children = [
                ...folders.map(folder => {
                  return {
                    name: folder.name,
                    id: folder.id,
                    onedriveId,
                    folderObj: { ...folder },
                    data: []
                  };
                })
              ];
            }
          }
          //Finally, we replace the onedrive in the state with the one with the subfolders.
          state.onedrives.splice(onedriveIdx, 1, copy);
        }
      }
    }
  },
  [SET_ONEDRIVE_DOCUMENTS](state, { onedriveId, documents, parentId, breadcrumbIds }) {
    const onedriveIdx = state.onedrives.findIndex(onedrive => onedrive.id === onedriveId);
    const onedrive = state.onedrives[onedriveIdx];
    if (onedrive) {
      let copy = { ...onedrive };
      //If parentId is Root, it means we fetched the documents of the onedrive.
      if (parentId === 'Root') {
        copy.data = [...documents];
        //In here we replace the onedrive in the array with the copy that contains the documents.
        state.onedrives.splice(onedriveIdx, 1, copy);
      } else {
        //If parentId is not Root, we fetched the documents for a subfolder
        //First we check the easiest case which is a direct child of the onedrive.
        const index = copy.children.findIndex(child => child.id === parentId);
        documents = [...documents];
        if (index !== -1) {
          copy.children[index].data = [...documents];
          state.onedrives.splice(onedriveIdx, 1, copy);
        } else {
          //If it's not a direct child of the onedrive, we follow the IDs in the breadcrumb
          //which allows us to follow the path to the selected folder for which we fetched
          //the documents.
          //We remove the first ID of the breadcrumb because it's the onedriveId
          breadcrumbIds.shift();
          //We're searching in the children of each folder the ID in the breadcrumb starting
          //with the onedrive children.
          let children = copy.children;
          for (let [index, value] of breadcrumbIds.entries()) {
            //we get the folder of each id in the breadcrumb and check if it's the last index:
            //If it is, we assign the documents to it's data property
            //If it is not, we search in the children of the current folder.
            const folder = children.find(child => child.id === value);
            if (index === breadcrumbIds.length - 1) {
              folder.data = [...documents];
            } else {
              children = folder.children;
            }
          }
          //Finally, we replace the onedrive in the state with the one with the documents.
          state.onedrives.splice(onedriveIdx, 1, copy);
        }
      }
    }
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
