import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_REPOSITORIES = 'O365/REPOSITORIES/SET_REPOSITORIES';

const REPOSITORIES_ENDPOINT = `${O365}/repositories`;

function initialState() {
  return {
    repositories: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_O365_REPOSITORIES({ commit }, organizationId) {
    const response = await axios.get(REPOSITORIES_ENDPOINT, { params: { organizationId } });
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  async GET_O365_REPOSITORY({ commit }, {organizationId, repositoryId}) {
    const response = await axios.get(REPOSITORIES_ENDPOINT, { params: { organizationId, repositoryId } });
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  async ADD_O365_REPOSITORY({ commit }, payload) {
    const response = await axios.post(REPOSITORIES_ENDPOINT, payload);
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  async EDIT_O365_REPOSITORY({ commit }, payload) {
    const response = await axios.patch(`${REPOSITORIES_ENDPOINT}/${payload.id}`, payload);
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  CLEAR_REPOSITORIES({commit}) {
    commit(SET_REPOSITORIES, []);
  }
};

const getters = {
  getRepositories: state => state.repositories
};

const mutations = {
  [SET_REPOSITORIES](state, data) {
    state.repositories = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
