<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Helpers } from '@/common/mixins/Helpers';

export default {
  mixins: [Helpers],
  computed: {
    ...mapGetters([
      'getThemeSettings',
      'brandingGetter',
      'isAuthenticated',
      'loggedInUser',
      'hsTokenGetter'
    ])
  },
  created() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      error => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.message &&
          error.response.data.message.includes('expired')
        ) {
          if (this.isAuthenticated) {
            this.dispatchSignOutExpired();
          }
        }
        return Promise.reject(error);
      }
    );
    if (this.brandingGetter?.id === 16) {
      this.$store.commit('serviceProviders/SET_IS_VMO', true);
      if (process.env.NODE_ENV === 'production') {
        let gtagScript = document.createElement('script');
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-X0MM3B5ZC8';
        gtagScript.async = true;
        document.head.appendChild(gtagScript);
        this.googlePixel();
      }
    }
    if (this.brandingGetter?.id === 28) {
      this.$store.commit('serviceProviders/SET_IS_HUNTERTECH', true);
    }
    if (this.brandingGetter?.id === 29) {
      this.$store.commit('serviceProviders/SET_IS_DIFFERENTDEV', true);
    }
    if ([1, 16].includes(this.brandingGetter?.id) && !document.getElementById('hubspotScript')) {
      let hubspotScript = document.createElement('script');
      hubspotScript.src = '//js.hs-scripts.com/5702752.js';
      hubspotScript.id = 'hubspotScript';
      hubspotScript.async = true;
      hubspotScript.defer = true;
      hubspotScript.type = 'text/javascript';
      document.head.appendChild(hubspotScript);
      this.checkHSAuthentication();
    }
  },
  mounted() {
    console.log('MOUNTED!', process.env.VUE_APP_VERSION);
    this.checkVersion();
    this.initializeLayout();
    this.updateUI();
  },
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    googlePixel() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-X0MM3B5ZC8');
      console.log('googlePixel');
    },
    updateUI() {
      if (this.getThemeSettings) {
        if (this.getThemeSettings.mode === 'dark') document.body.classList.remove('white-content');
        else document.body.classList.add('white-content');
        if (this.getThemeSettings.sidebarMini !== this.$sidebar.isMinimized)
          this.$sidebar.toggleMinimize();
      }
    },
    checkVersion() {
      const savedAppVersion = localStorage.getItem('appVersion');
      if (!savedAppVersion || savedAppVersion !== process.env.VUE_APP_VERSION) {
        localStorage.setItem('appVersion', process.env.VUE_APP_VERSION);
        this.$store.dispatch('LOG_OUT').then(() => this.$router.push('/login'));
        localStorage.setItem('appVersion', process.env.VUE_APP_VERSION);
      }
    },
    dispatchSignOutExpired() {
      this.$store.dispatch('LOG_OUT').then(() => {
        this.$router.push('/login');
        this.$notifyInfo('Your session has expired.\nPlease log in again to continue.');
      });
    },
    checkHSAuthentication() {
      if (this.isAuthenticated) {
        try {
          window.hsConversationsSettings = {
            loadImmediately: false
          };
          this.$store
            .dispatch('GET_HS_TOKEN', {
              name: this.loggedInUser.name,
              email: this.loggedInUser.email,
              serviceProviderId: this.loggedInUser.serviceProviderId
            })
            .then(() => {
              window.hsConversationsSettings = {
                identificationEmail: this.loggedInUser.email,
                identificationToken: this.hsTokenGetter
              };
              window.HubSpotConversations?.resetAndReloadWidget();
            });
        } catch (error) {
          console.log({ error });
        }
      }
    }
  },
  watch: {
    getThemeSettings: function () {
      this.updateUI();
    },
    brandingGetter: function () {
      if (
        this.brandingGetter.title.includes('Managecast') &&
        !document.getElementById('hubspotScript')
      ) {
        let hubspotScript = document.createElement('script');
        document.head.appendChild(hubspotScript);
        hubspotScript.src = '//js.hs-scripts.com/5702752.js';
      }
    },
    isAuthenticated: function (value) {
      if (value) {
        if (window.HubSpotConversations) {
          window.HubSpotConversations.widget.remove();
          this.checkHSAuthentication();
        }
      }
    },
    'window.HubSpotConversations': function (value) {
      if (value) {
        window.HubSpotConversations.widget.remove();
        this.checkHSAuthentication();
      }
    }
  }
};
</script>

<style lang="scss">
@import 'vue-loading-overlay/dist/vue-loading.css';
@import './common/assets/sass/styles/styles.scss';
@import './common/assets/css/restore-icons.css';
</style>
