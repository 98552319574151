import Vue from 'vue';
import DashboardPlugin from './common/plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios';
import VueTheMask from 'vue-the-mask';
import Datetime from 'vue-datetime';
import config from './env';
import { VueReCaptcha } from 'vue-recaptcha-v3';

// router setup
import router from './router';
import i18n from './i18n';
import store from './store';
import Loading from 'vue-loading-overlay';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Loading, { opacity: 0.2, zIndex: 1061 });
Vue.use(VueReCaptcha, {
  siteKey: '6Lc700wmAAAAAGe_gQWmB9Ez2NDSntsKi2BjaEVG ',
  loaderOptions: {
    autoHideBadge: true,
    explicitRenderParameters: {
      badge: 'bottomleft'
    }
  }
});

// Plugins from Onboarding Project
Vue.use(Datetime);
Vue.use(VueTheMask);
// end

// Axios setup
// After branding API call set the proper baseURL
axios.defaults.baseURL = config.MCAST_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('userToken');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const vueApp = new Vue({ store });
const loader = vueApp.$loading.show({ opacity: 1 });
vueApp.$store
  .dispatch('GET_BRANDING')
  .then(
    () =>
      /* eslint-disable no-new */
      new Vue({
        el: '#app',
        render: h => h(App),
        router,
        store,
        i18n
      })
  )
  .finally(() => loader.hide());
