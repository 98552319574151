import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import FileDownload from 'js-file-download';
import { O365 } from '@/common/data/constants';

const SET_MAILBOXES = 'RESTORE/SET_MAILBOXES';
const SET_MAILBOX_FOLDERS = 'RESTORE/SET_MAILBOX_FOLDERS';
const SET_MAILBOX_ITEMS = 'RESTORE/SET_MAILBOX_ITEMS';

const BASE_RESTORE_ENDPOINT = `${O365}/restore`;
const RESTORE_SESSION_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/restoresessions/:restoreSessionId`;
const MAILBOXES_ENDPOINT = `${RESTORE_SESSION_ENDPOINT}/mailboxes`;
const SINGLE_MAILBOX_ENDPOINT = `${MAILBOXES_ENDPOINT}/:mailboxId`;
const RESTORE_MAILBOX_OGLOC_ENDPOINT = `${SINGLE_MAILBOX_ENDPOINT}/restore/originalloc`;
const RESTORE_MAILBOX_ANOTHERLOC_ENDPOINT = `${SINGLE_MAILBOX_ENDPOINT}/restore/anotherloc`;
const DOWNLOAD_MAILBOX_ENDPOINT = `${SINGLE_MAILBOX_ENDPOINT}/download`;
const MAILBOX_FOLDERS_ENDPOINT = `${MAILBOXES_ENDPOINT}/:mailboxId/folders`;
const SINGLE_MAILBOX_FOLDER_ENDPOINT = `${MAILBOX_FOLDERS_ENDPOINT}/:folderId`;
const RESTORE_MAILBOX_FOLDER_OGLOC_ENDPOINT = `${SINGLE_MAILBOX_FOLDER_ENDPOINT}/restore/originalloc`;
const RESTORE_MAILBOX_FOLDER_ANOTHERLOC_ENDPOINT = `${SINGLE_MAILBOX_FOLDER_ENDPOINT}/restore/anotherloc`;
const DOWNLOAD_MAILBOX_FOLDER_ENDPOINT = `${SINGLE_MAILBOX_FOLDER_ENDPOINT}/download`;
const MAILBOX_ITEMS_ENDPOINT = `${MAILBOXES_ENDPOINT}/:mailboxId/items`;
const RESTORE_MAILBOX_ITEMS_OGLOC_ENDPOINT = `${MAILBOX_ITEMS_ENDPOINT}/restore/originalloc`;
const RESTORE_MAILBOX_ITEMS_ANOTHERLOC_ENDPOINT = `${MAILBOX_ITEMS_ENDPOINT}/restore/anotherloc`;
const DOWNLOAD_MAILBOX_ITEMS_ENDPOINT = `${MAILBOX_ITEMS_ENDPOINT}/download`;

function initialState() {
  return {
    mailboxes: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_MAILBOXES({ commit }, { restoreSessionId, params }) {
    try {
      const response = await axios.get(
        MAILBOXES_ENDPOINT.replace(':restoreSessionId', restoreSessionId),
        {
          params: { ...params },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_MAILBOXES, response.data?.data?.results || response.data?.data);
    } catch (error) {
      console.error(error);
      throw 'Error getting the mailboxes. Try again later.';
    }
  },
  CLEAR_MAILBOXES({ commit }) {
    commit(SET_MAILBOXES, []);
  },
  async RESTORE_MAILBOX_OGLOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        deletedItems: params.deletedItems,
        changedItems: params.changedItems,
        markRestoredAsUnread: params.markRestoredAsUnread,
        excludeDeletedItems: params.excludeDeletedItems,
        excludeDrafts: params.excludeDrafts,
        excludeInPlaceHoldItems: params.excludeInPlaceHoldItems,
        excludeLitigationHoldItems: params.excludeLitigationHoldItems
      };
      await axios.post(
        RESTORE_MAILBOX_OGLOC_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ).replace(':mailboxId', params.mailboxId),
        data
      );
    } catch (error) {
      if (error.response?.data?.data?.message) throw error.response.data.data.message;
      else throw 'Error restoring the mailbox. Try again later.';
    }
  },
  async RESTORE_MAILBOX_ANOTHERLOC(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        deletedItems: params.deletedItems,
        changedItems: params.changedItems,
        markRestoredAsUnread: params.markRestoredAsUnread,
        excludeDeletedItems: params.excludeDeletedItems,
        excludeDrafts: params.excludeDrafts,
        excludeInPlaceHoldItems: params.excludeInPlaceHoldItems,
        excludeLitigationHoldItems: params.excludeLitigationHoldItems,
        folder: params.folder,
        mailbox: params.mailbox
      };
      await axios.post(
        RESTORE_MAILBOX_ANOTHERLOC_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ).replace(':mailboxId', params.mailboxId),
        data
      );
    } catch (error) {
      if (error.response?.data?.data?.message) throw error.response.data.data.message;
      else throw 'Error restoring the mailbox. Try again later.';
    }
  },
  async DOWNLOAD_MAILBOX(context, params) {
    try {
      const data = { companyId: params.companyId, organizationId: params.organizationId };
      const response = await axios.post(
        DOWNLOAD_MAILBOX_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId).replace(
          ':mailboxId',
          params.mailboxId
        ),
        data,
        { headers: { Accept: 'application/octet-stream' }, responseType: 'blob' }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.name}-mailbox.pst`);
    } catch (error) {
      throw 'Error downloading mailbox. Try again later.';
    }
  },
  async GET_MAILBOX_FOLDERS(
    { commit },
    { restoreSessionId, mailboxId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        MAILBOX_FOLDERS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':mailboxId',
          mailboxId
        ),
        {
          params: { ...params, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_MAILBOX_FOLDERS, {
        mailboxId,
        folders: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
    } catch (error) {
      throw 'Error getting the mailbox folders. Try again later.';
    }
  },
  CLEAR_MAILBOX_FOLDERS({ commit }) {
    commit(SET_MAILBOX_FOLDERS, []);
  },
  async RESTORE_MAILBOX_FOLDER_OGLOC(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        deletedItems: params.deletedItems,
        changedItems: params.changedItems,
        markRestoredAsUnread: params.markRestoredAsUnread
      };
      await axios.post(
        RESTORE_MAILBOX_FOLDER_OGLOC_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId)
          .replace(':mailboxId', params.mailboxId)
          .replace(':folderId', params.folderId),
        data
      );
    } catch (error) {
      if (error.response?.data?.data?.message) throw error.response.data.data.message;
      else throw 'Error restoring the mailbox folder. Try again later.';
    }
  },
  async RESTORE_MAILBOX_FOLDER_ANOTHERLOC(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        deletedItems: params.deletedItems,
        changedItems: params.changedItems,
        markRestoredAsUnread: params.markRestoredAsUnread,
        folder: params.folder,
        mailbox: params.mailbox
      };
      await axios.post(
        RESTORE_MAILBOX_FOLDER_ANOTHERLOC_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        )
          .replace(':mailboxId', params.mailboxId)
          .replace(':folderId', params.folderId),
        data
      );
    } catch (error) {
      if (error.response?.data?.data?.message) throw error.response.data.data.message;
      else throw 'Error restoring the mailbox folder. Try again later.';
    }
  },
  async DOWNLOAD_MAILBOX_FOLDER(context, params) {
    try {
      const data = { companyId: params.companyId, organizationId: params.organizationId };
      const response = await axios.post(
        DOWNLOAD_MAILBOX_FOLDER_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId)
          .replace(':mailboxId', params.mailboxId)
          .replace(':folderId', params.folderId),
        data,
        { headers: { Accept: 'application/octet-stream' }, responseType: 'blob' }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.name}-mailbox-folder.pst`);
    } catch (error) {
      throw 'Error downloading mailbox. Try again later.';
    }
  },
  async GET_MAILBOX_ITEMS(
    { commit },
    { restoreSessionId, mailboxId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        MAILBOX_ITEMS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':mailboxId',
          mailboxId
        ),
        {
          params: { ...params, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_MAILBOX_ITEMS, {
        mailboxId,
        items: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
      return response.data?.data?.results || response.data?.data;
    } catch (error) {
      throw 'Error getting the mailbox items. Try again later.';
    }
  },
  async RESTORE_MAILBOX_ITEMS_OGLOC(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        items: params.items,
        deletedItems: params.deletedItems,
        changedItems: params.changedItems,
        markRestoredAsUnread: params.markRestoredAsUnread
      };
      await axios.post(
        RESTORE_MAILBOX_ITEMS_OGLOC_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ).replace(':mailboxId', params.mailboxId),
        data
      );
    } catch (error) {
      if (error.response?.data?.data?.message) throw error.response.data.data.message;
      else throw 'Error restoring the mailbox folder. Try again later.';
    }
  },
  async RESTORE_MAILBOX_ITEMS_ANOTHERLOC(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        items: params.items,
        folder: params.folder,
        mailbox: params.mailbox,
        deletedItems: params.deletedItems,
        changedItems: params.changedItems,
        markRestoredAsUnread: params.markRestoredAsUnread
      };
      await axios.post(
        RESTORE_MAILBOX_ITEMS_ANOTHERLOC_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ).replace(':mailboxId', params.mailboxId),
        data
      );
    } catch (error) {
      if (error.response?.data?.data?.message) throw error.response.data.data.message;
      else throw 'Error restoring the mailbox folder. Try again later.';
    }
  },
  async DOWNLOAD_MAILBOX_ITEMS(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        items: params.items,
        format: params.format
      };
      const response = await axios.post(
        DOWNLOAD_MAILBOX_ITEMS_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ).replace(':mailboxId', params.mailboxId),
        data,
        { headers: { Accept: 'application/octet-stream' }, responseType: 'blob' }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-mailbox-items.${params.format}`);
    } catch (error) {
      throw 'Error downloading mailbox. Try again later.';
    }
  }
};

const getters = {
  getMailboxes: state => state.mailboxes
};

const mutations = {
  [SET_MAILBOXES](state, data) {
    state.mailboxes = data;
  },
  [SET_MAILBOX_FOLDERS](state, { mailboxId, folders, parentId, breadcrumbIds }) {
    const mailboxIdx = state.mailboxes.findIndex(mb => mb.id === mailboxId);
    const mailbox = state.mailboxes[mailboxIdx];
    if (mailbox) {
      let copy = { ...mailbox };
      if (parentId === 'Root') {
        copy.children = [
          ...folders.map(folder => {
            return {
              name: folder.name,
              id: folder.id,
              mailboxId
            };
          })
        ];
        state.mailboxes.splice(mailboxIdx, 1, copy);
      } else {
        const index = copy.children.findIndex(child => child.id === parentId);
        if (index !== -1) {
          copy.children[index].children = [
            ...folders.map(folder => {
              return {
                name: folder.name,
                id: folder.id,
                mailboxId
              };
            })
          ];
          state.mailboxes.splice(mailboxIdx, 1, copy);
        } else {
          breadcrumbIds.shift();
          let children = copy.children;
          for (let id of breadcrumbIds) {
            const folder = children.find(child => child.id === id);
            if (folder?.children) {
              children = folder.children;
            } else {
              folder.children = [
                ...folders.map(folder => {
                  return {
                    name: folder.name,
                    id: folder.id,
                    mailboxId
                  };
                })
              ];
            }
          }
          state.mailboxes.splice(mailboxIdx, 1, copy);
        }
      }
    }
  },
  [SET_MAILBOX_ITEMS](state, { mailboxId, items, parentId, breadcrumbIds }) {
    const mailboxIdx = state.mailboxes.findIndex(mb => mb.id === mailboxId);
    const mailbox = state.mailboxes[mailboxIdx];
    if (mailbox) {
      let copy = { ...mailbox };
      //If parentId is Root, it means we fetched the items of the mailbox.
      if (parentId === 'Root') {
        copy.data = [...items];
        //In here we replace the mailbox in the array with the copy that contains the items.
        state.mailboxes.splice(mailboxIdx, 1, copy);
      } else {
        //If parentId is not Root, we fetched the items for a subfolder
        //First we check the easiest case which is a direct child of the mailbox.
        const index = copy.children.findIndex(child => child.id === parentId);
        if (index !== -1) {
          copy.children[index].data = [...items];
          state.mailboxes.splice(mailboxIdx, 1, copy);
        } else {
          //If it's not a direct child of the mailbox, we follow the IDs in the breadcrumb
          //which allows us to follow the path to the selected folder for which we fetched
          //the items.
          //We remove the first ID of the breadcrumb because it's the mailboxId
          breadcrumbIds.shift();
          //We're searching in the children of each folder the ID in the breadcrumb starting
          //with the mailbox children.
          let children = copy.children;
          for (let [index, value] of breadcrumbIds.entries()) {
            //we get the folder of each id in the breadcrumb and check if it's the last index:
            //If it is, we assign the items to it's data property
            //If it is not, we search in the children of the current folder.
            const folder = children.find(child => child.id === value);
            if (index === breadcrumbIds.length - 1) {
              folder.data = [...items];
            } else {
              children = folder.children;
            }
          }
          //Finally, we replace the mailbox in the state with the one with the items.
          state.mailboxes.splice(mailboxIdx, 1, copy);
        }
      }
    }
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
