<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <!-- <div class="py-2 logo-mini">
          <img :src="logo" alt="app-logo" :width="logoWidth || 34" :height="logoHeight || 34" />
        </div> -->
        <div class="simple-text logo-normal d-flex w-100 pl-2">
          <div>
            {{ title }}
          </div>
          <div class="fix-sidebar-icon">
            <div class="fix-icons">
              <i
                v-if="getThemeSettings && getThemeSettings.sidebarMini"
                class="tim-icons icon-pin"
                @click="updateSidebarMini(false)"
              />
              <i v-else class="tim-icons icon-pin rotate-45" @click="updateSidebarMini(true)" />
            </div>
            <div class="navbar-wrapper" style="margin-top:3px;">
              <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
                <button type="button" class="navbar-toggler" @click="toggleSidebar">
                  <span class="navbar-toggler-bar bar1"></span>
                  <span class="navbar-toggler-bar bar2"></span>
                  <span class="navbar-toggler-bar bar3"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            ></sidebar-item>
          </sidebar-item>
        </slot>
        <div class="sidebar-footer">
          <div
            v-if="userHasAccessToAnotherApp"
            id="app-container-div"
            v-click-outside="hideAppContainer"
            @click="displayAppContainer"
          >
            <sidebar-item
              :link="{
                name: `Apps (${getSelectedApp === 'monitoring' ? 'Monitoring' : 'M365' })`,
                icon: 'fas fa-th-large'
              }"
              menu
            />
          </div>
          <div v-if="getSelectedApp === 'monitoring' && lastSync" class="sync">
            <el-tooltip
              :content="
                `Failed: ${lastSync.failed && lastSync.failed.map(f => f && f.entity).join(', ')}`
              "
              effect="light"
              :open-delay="400"
              placement="top"
              :disabled="lastSync.status === 'SUCCEEDED'"
            >
              <ul class="nav">
                <li @click="getLastSync" :class="{ 'disabled-sync': gettingLastSync }">
                  <Spinner id="spinner" size="24" v-if="gettingLastSync" />
                  <i class="tim-icons icon-refresh-02" v-else />
                  <p>{{ lastSync.status }}</p>
                </li>
              </ul>
            </el-tooltip>
          </div>
          <sidebar-item :link="{ name: 'Settings', icon: 'tim-icons icon-settings' }">
            <sidebar-item
              :link="{
                name: 'Profile',
                path: `/${getSelectedApp}/profile`
              }"
            />
            <sidebar-item
              v-if="getSelectedApp === 'o365' && brandingGetter.isStripeEnabled"
              :link="{
                name: 'Subscriptions',
                path: `/${getSelectedApp}/subscriptions`
              }"
            />
            <sidebar-item
              :link="{
                name: 'Change Password',
                path: `/${getSelectedApp}/change-password`
              }"
            />
          </sidebar-item>
          <div id="logout-div" @click="doLogout">
            <sidebar-item
              :link="{
                name: logOutLabel,
                icon: 'tim-icons icon-button-power'
              }"
              :menu="true"
            />
          </div>
        </div>
      </ul>
    </div>
    <div v-if="displayApps" class="browse-apps-container">
      <button @click="redirectTo(MONITORING)" :class="{ 'selectedApp': getSelectedApp === 'monitoring' }">
        <i class="fas fa-desktop" />
        Monitoring
      </button>
      <button @click="redirectTo(O365)" :class="{ 'selectedApp': getSelectedApp === 'o365' }">
        <i class="fab fa-windows" />
        Microsoft365
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'vue-simple-spinner';
import { Modal } from '@/common/components';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { USER_ROLES, MONITORING, O365 } from '@/common/data/constants';

export default {
  name: 'sidebar',
  components: { Modal, Spinner },
  props: {
    title: { type: String, default: 'Portal', description: 'Sidebar title' },
    shortTitle: { type: String, default: 'CT', description: 'Sidebar short title' },
    logo: {
      type: String,
      // default: '/img/isologo.png',
      description: 'Sidebar app logo'
    },
    logoWidth: { type: [String, Number], default: '34' },
    logoHeight: { type: [String, Number], default: '34' },
    backgroundColor: {
      type: String,
      default: 'blue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'higher-purple',
          'light-black',
          'light-gray',
          'medium-gray',
          'dark-gray',
          'white'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar background color'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  computed: {
    ...mapGetters([
      'getThemeSettings',
      'loggedInUser',
      'lastSync',
      'getIsLoggedInAs',
      'getSelectedApp',
      'brandingGetter'
    ]),
    logOutLabel() {
      if (this.getIsLoggedInAs) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let username = userInfo.username;
        if (username.length > 10) username = username.substring(0, 10) + '...';
        return `Exit ${username}`;
      } else return 'Log Out';
    },
    userHasAccessToAnotherApp() {
      return !!this.loggedInUser?.acl?.find(a => a.role === USER_ROLES.O365_ADMIN);
    }
  },
  data() {
    return {
      searchModalVisible: false,
      searchQuery: '',
      UI_ABILITIES,
      gettingLastSync: false,
      displayApps: false,
      MONITORING,
      O365
    };
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  mounted() {
    this.getLastSync();
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    updateSidebarMini(sidebarMini) {
      this.minimizeSidebar();
      const updatedTheme = { sidebarMini };
      this.$store
        .dispatch('UPDATE_USER', {
          _id: this.loggedInUser.id,
          theme: updatedTheme
        })
        .then(() => {})
        .catch(() => {});
      this.$store.dispatch('ACTION_SET_THEME_SETTINGS', updatedTheme);
    },
    doLogout() {
      if (this.getIsLoggedInAs) {
        this.$store.dispatch('EXIT_FROM_LOGGED_IN_AS');
      } else this.$store.dispatch('LOG_OUT').then(() => this.$router.push('/login'));
    },
    getLastSync() {
      this.gettingLastSync = true;
      this.$store.dispatch('GET_LAST_SYNC').finally(() => (this.gettingLastSync = false));
    },
    redirectTo(app) {
      if (app === O365) {
        if (this.loggedInUser.landingApp.page)
          return this.$router.push(this.loggedInUser.landingApp.page);
        else return this.$router.push(`/${O365}`);
      }
      this.$router.push(`/${app}`);
    },
    displayAppContainer() {
      this.displayApps = !this.displayApps;
    },
    hideAppContainer() {
      this.displayApps = false;
    }
  }
};
</script>

<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
#divWithButton .nav li {
  margin: 0px 15px 0px;
  border-radius: 30px;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 8px;
  line-height: 1.625rem;
}
.fix-sidebar-icon {
  margin-left: auto;
  cursor: pointer;
  @media (max-width: 1200px) {
    .fix-icons {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-wrapper {
      display: none;
    }
  }
  .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 20px;
    height: 1px;
    border-radius: 1px;
    background: white;

    &.navbar-kebab {
      height: 4px;
      width: 4px;
      margin-bottom: 3px;
      border-radius: 50%;
    }

    & + .navbar-toggler-bar {
      margin-top: 7px;
    }

    & + .navbar-toggler-bar.navbar-kebab {
      margin-top: 0px;
    }

    &.bar2 {
      width: 15px;
      transition: width 0.2s linear;
    }
  }
}
#logout-div,
#app-container-div {
  .caret {
    display: none;
  }
}
.browse-apps-container {
  position: absolute;
  padding: 15px;
  bottom: 150px;
  left: 35px;
  width: 280px;
  height: 100px;
  border-radius: 5px;
  background: #f5f6fa;
  z-index: 3000;
  border: 2px solid gray;
  display: flex;
  flex-wrap: wrap;
}
.browse-apps-container button {
  max-height: 60px;
  margin: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.browse-apps-container button i {
  font-size: 30px;
}
.browse-apps-container button:hover {
  opacity: 0.4;
}
.browse-apps-container button:focus {
  outline: none;
}
.disabled-sync {
  pointer-events: none;
}
#spinner {
  float: left;
  margin-right: 16px;
  margin-left: 6px;
}
.rotate-45 {
  transform: rotate(-45deg);
}
.sync {
  // height: 40px;
  margin-left: 15px;
  padding-left: 4px;
  cursor: pointer;
  & .caret {
    display: none;
  }
}
.disabled-sync {
  pointer-events: none;
}
</style>

<style scoped lang="scss">
.selectedApp {
  pointer-events: none;
  opacity: 0.4;
}
</style>
