import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import auth from '../common/store/auth';
import app from '../common/store/app';
import notifications from '../common/store/notifications';
import jobs from '../monitoring/store/jobs';
import analytics from '../monitoring/store/analytics';
import reporting from '../monitoring/store/reporting';
import rules from '../monitoring/store/rules';
import tickets from '../monitoring/store/tickets';
import users from '../monitoring/store/users';
import companies from '../monitoring/store/companies';
import serviceProviders from '../monitoring/store/serviceProviders';
import wizOrganizations from '../o365/store/wizards/organizations';
import o365Repositories from '../o365/store/repositories';
import wizJobs from '../o365/store/wizards/jobs';
import o365Proxies from '../o365/store/proxies';
import o365Organizations from '../o365/store/organizations';
import o365Jobs from '../o365/store/jobs';
import O365Licensing from '../o365/store/licensing';
import wizRestoreOpRole from '../o365/store/wizards/restore-operator-roles';
import o365RestoreOpRoles from '../o365/store/restore-operator-roles';
import o365Version from '../o365/store/version';
import dashboard from '../o365/store/dashboard';
import o365Provisioning from '../o365/store/provisioning';
import subscriptions from '../o365/store/subscriptions';
import hints from '../monitoring/store/hints';
import o365Restore from '../o365/store/restore/restore';
import restoreOutlook from '../o365/store/restore/outlook';
import restoreOneDrive from '../o365/store/restore/onedrive';
import restoreTeams from '../o365/store/restore/teams';
import restoreSharepoint from '../o365/store/restore/sharepoint';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    jobs,
    users,
    reporting,
    analytics,
    rules,
    tickets,
    companies,
    serviceProviders,
    app,
    wizOrganizations,
    o365Repositories,
    o365Proxies,
    wizJobs,
    o365Organizations,
    o365Jobs,
    O365Licensing,
    wizRestoreOpRole,
    o365RestoreOpRoles,
    o365Version,
    o365Provisioning,
    hints,
    subscriptions,
    notifications,
    dashboard,
    o365Restore,
    restoreOutlook,
    restoreOneDrive,
    restoreTeams,
    restoreSharepoint
  },
  strict: debug
});
