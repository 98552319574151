import { render, staticRenderFns } from "./BaseCustomInput.vue?vue&type=template&id=3b120dd9&scoped=true"
import script from "./BaseCustomInput.vue?vue&type=script&lang=js"
export * from "./BaseCustomInput.vue?vue&type=script&lang=js"
import style0 from "./BaseCustomInput.vue?vue&type=style&index=0&id=3b120dd9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b120dd9",
  null
  
)

export default component.exports